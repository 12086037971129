.cursor-auto {
  cursor: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.red-border-box {
  border: 1px solid red !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

.display-none {
  display: none !important;
}

.display-block {
  display: block !important;
}

.display-flex {
  display: flex !important;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  12.5% {
    transform: translateX(-6px) rotateY(-9deg) skewY(1deg);
  }

  37.5% {
    transform: translateX(5px) rotateY(4.5deg) skewY(-1deg);
  }

  62.5% {
    transform: translateX(-3px) rotateY(-2.25deg) skewY(0);
  }

  87.5% {
    transform: translateX(2px) rotateY(3deg);
  }

  100% {
    transform: translateX(0);
  }
}

.headShake {
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0, 0.23, 1, 0.71);
  animation-name: headShake;
}

/*User Profile*/
.verification-button {
  float: right;
  font-weight: 500;
  text-decoration: none;
  color: #ff0000;
  cursor: pointer;
  font-size: 11px;
}

.cursor-pointer-none {
  pointer-events: none;
}

.custom-divider {
  width: 100%;
  border-top: 1px solid #e3e3e3;
  margin: 4px 0px;
}

.pr-20 {
  padding-right: 20px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mt-12 {
  margin-top: 12px !important;
}