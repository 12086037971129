/* guest parking page css */

.guest-parking-wrapper .back-icon {
	font-size: 1.75rem !important;
	line-height: 120% !important;
	cursor: pointer !important;
}

.guest-bay-type-wrapper .select-heading {
	padding-bottom: 3px;
}

.guest-bay-usage-menu {
	justify-content: space-between;
	list-style: none;
	width: 100%;
	display: grid;
	gap: 9px;
	grid-template-columns: repeat(13, 1fr);
	max-height: 141px;
	overflow-y: auto;
	padding: 5px 6px 5px 0;
	overflow-x: auto;
}

.guest-bay-usage-menu li span {
	display: block;
	width: 36px;
	height: 65px;
	background-color: #ffffff;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.3);
	text-decoration: none;
	font-size: 0.75rem;
	line-height: 65px;
	text-align: center;
	font-weight: 500;
	color: #000000;
}

.guest-bay-usage-icon {
	cursor: not-allowed;
}

.guest-bay-usage-icon .available-bay,
.guest-bay-usage-icon .selected-bay {
	cursor: pointer;
}

.guest-parking-wrapper .unavailable-bay-alert .bay-warning-icon ,
.assigned-bay-wrapper .unavailable-bay-alert .bay-warning-icon {
	width: 20px;
	height: 20px;
}

.guest-parking-wrapper .unavailable-bay-alert,
.assigned-bay-wrapper .unavailable-bay-alert {
	height: 20px;
}

.guest-parking-wrapper .unavailable-bay-alert p.bay-warning-text,
.assigned-bay-wrapper .unavailable-bay-alert p.bay-warning-text {
	margin-top: 2px;
}
.guest-parking-wrapper .guest-timepicker-autocomplete .MuiAutocomplete-input {
	padding: 7px;
}

/* width */
.guest-parking-wrapper ::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* Track */
.guest-parking-wrapper ::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #f1f1f1;
	border-radius: 10px;
	background: #f1f1f1;
}

/* Handle */
.guest-parking-wrapper ::-webkit-scrollbar-thumb {
	background: #9e9e9e;
	border-radius: 10px;
}

/* Handle on hover */
.guest-parking-wrapper ::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.guest-transaction-reset-filter {
	cursor: pointer;
	margin-top: 36px;
	color: rgb(255, 0, 0);
	white-space: pre;
}

@media only screen and (max-width: 1096px) {
	.guest-transaction-reset-filter {
		margin-top: 51px;
	}
}

.transaction-export-to-csv-box {
	position: absolute;
    right: 0;
    cursor: pointer;
    font-weight: 500;
    font-size: 0.875rem;
    color: rgb(255, 0, 0);
    display: flex;
    align-items: center;
}
.guest-transaction-export {
	bottom: 14px;
}
.on-demand-transaction-export {
	bottom: 8px;
}

/*********************** guest parking page css ***********************/

.assign-parking-wrapper .back-icon {
	font-size: 1.75rem !important;
	line-height: 120% !important;
	cursor: pointer !important;
	margin-bottom: 0 !important
}


/* block out model css */

.block-out-timepicker-autocomplete  .MuiAutocomplete-input {
	padding: 6.5px !important;
	font-size: 14px !important;
}

/* Assign parking page css */

.assign-parking-timepicker-autocomplete .MuiAutocomplete-inputRoot {
	padding: 2px 20px 2px 0px !important;
	font-size: 11px;
}
.assign-parking-timepicker-autocomplete .MuiAutocomplete-input {
	text-align: center;
}
.assign-parking-timepicker-autocomplete .MuiAutocomplete-endAdornment {
	right: 0 !important;
}
.assign-parking-timepicker-autocomplete .MuiFormHelperText-root {
	width: 105%;
    text-align: left;
    font-size: 9px;
}

.assign-parking-wrapper .custom-input-label {
	display: block;
	width: 100%;
	font-size: 0.875rem;
	text-align: left;
	font-weight: 500;
	margin-bottom: 4px;
}
.assign-parking-wrapper .radio-group-label {
	margin: 10px 0 0 0;
    width: 50%;
}
.assign-parking-wrapper .user-search-box {
	margin-top: 30px;
}
.assign-parking-wrapper .time-selection-autocomplete .MuiInputBase-input.Mui-disabled{
	cursor: not-allowed;
}
.assign-parking-wrapper .time-selection-autocomplete .MuiInputBase-root.Mui-disabled{
	background: #f6f6f6;
}


/* user-territory-and-facility */
.territory-form-control-label {
	width: 50%;
	margin-right: 0px;
}
.territory-form-control-label .MuiTypography-root {
	font-weight: 500;
}
.territory-form-control-label.bold {
	color: #000000;
}
.territory-form-control-label.light .MuiTypography-root {
	font-weight: 400;
}

/* manage-users */

/* width */
.add-user-modal ::-webkit-scrollbar {
	width: 7px;
	height: 5px;
}

/* Track */
.add-user-modal ::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #f1f1f1;
	border-radius: 10px;
	background: #f1f1f1;
}

/* Handle */
.add-user-modal ::-webkit-scrollbar-thumb {
	background: #9e9e9e;
	border-radius: 10px;
}

/* Handle on hover */
.add-user-modal ::-webkit-scrollbar-thumb:hover {
	background: #555;
}


.content-wrapping-parker {
	white-space: nowrap;
	text-overflow:ellipsis;
	overflow: hidden;
	max-width: 180px;
	min-width: 180px;
}

.content-wrapping-location {
	white-space: nowrap;
	text-overflow:ellipsis;
	overflow: hidden;
	max-width: 160px;
	min-width: 160px;
	padding-right: 20px !important;
}

.content-wrapping-bay {
	white-space: nowrap;
	text-overflow:ellipsis;
	overflow: hidden;
	max-width: 250px;
	min-width: 250px;
}

/* bulk uploads page css */

.bulk-uploads-section .download-exceptions-files {
	display: flex;
    align-items: center;
    justify-content: right;
}

.bulk-uploads-section .download-exceptions-files .exceptions-record-count {
	color: red;
}

.bulk-uploads-section .download-exceptions-files .exceptions-record-file {
	color: red;
	cursor: pointer;
	margin-left: 8px;
}

.download-exceptions-label {
	padding-right: 32px;	
}


.header-tenant-selector-wrapper {
	width: 250px;
    display: flex;
    align-items: center;
    height: 40px;
}

.header-tenant-selector-wrapper .selector-label {
    font-size: 0.875rem;
    line-height: 20px;
    font-weight: normal;
    color: black;
	margin-right: 4px;
}

.header-tenant-selector-wrapper  .MuiSelect-select {
	padding: 0 18px 0 4px !important;
	text-align: left;
}

.header-tenant-selector-wrapper  fieldset {
	padding: 0 !important;
}

.header-tenant-selector-wrapper .MuiSelect-icon {
	margin-top: 1px !important;
}

.header-tenant-selector-wrapper-mob {
	display: flex;
	align-items: center;
}

.header-tenant-selector-wrapper-mob .selector-label {
	margin-right: 10px;
}